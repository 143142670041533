<template>
  <div class="container">
    <div class="module-head">
      <h2>温馨提醒：亲爱的商家们，因目前版本更新较快，以下部分内容可能与最新版本有所不符。若出现不符的情况，请以您使用的最新版本为准。</h2>
      <div class="module-train">
        <div class="box">
          <h1>培训视频专区</h1>
          <div v-if="detail[0] && detail[0].length > 0">
            <a
              v-for="(item, index) in detail[0]"
              :key="index"
              :href="item.file_url"
              target="_blank"
            >
              {{ `【视频】${item.title}（${item.create_time}）` }}
              <b
                v-if="item.is_new === 1"
                class="red"
              >新</b>
            </a>
          </div>
        </div>
        <div class="box">
          <h1>培训文件专区</h1>
          <div v-if="detail[1] && detail[1].length > 0">
            <a
              v-for="(item, index) in detail[1]"
              :key="index"
              :href="item.file_url"
              target="_blank"
            >
              {{ `${index + 1}、${item.title}` }}
              <b
                v-if="item.is_new === 1"
                class="red"
              >新</b>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="module"
      style="border-bottom: 1px solid #ccc;"
    >
      <div class="box">
        <img
          src="../../assets/images/help_xx.png"
          alt="信息"
        />
        <h4>一、必填信息</h4>
        <ol v-if="detail[2] && detail[2].length > 0">
          <li
            v-for="(item, index) in detail[2]"
            :key="index"
          >
            <a
              target="_blank"
              :href="item.file_url"
            >
              {{ item.title }}
              <b
                v-if="item.is_new === 1"
                class="red"
              >新</b>
            </a>
          </li>
        </ol>
      </div>
      <div class="box">
        <img
          src="../../assets/images/help_sp.png"
          alt="商品"
        />
        <h4>二、商品相关</h4>
        <ol v-if="detail[3] && detail[3].length > 0">
          <li
            v-for="(item, index) in detail[3]"
            :key="index"
          >
            <a
              target="_blank"
              :href="item.file_url"
            >
              {{ item.title }}
              <b
                v-if="item.is_new === 1"
                class="red"
              >新</b>
            </a>
          </li>
        </ol>
      </div>
      <div
        class="box"
        style="border-right: none;"
      >
        <img
          src="../../assets/images/help_yx.png"
          alt="营销"
        />
        <h4>三、营销相关</h4>
        <ol v-if="detail[4] && detail[4].length > 0">
          <li
            v-for="(item, index) in detail[4]"
            :key="index"
          >
            <a
              target="_blank"
              :href="item.file_url"
            >
              {{ item.title }}
              <b
                v-if="item.is_new === 1"
                class="red"
              >新</b>
            </a>
          </li>
        </ol>
      </div>
    </div>
    <div class="module">
      <div class="box">
        <img
          src="../../assets/images/help_fh.png"
          alt="发货"
        />
        <h4>四、发货相关</h4>
        <ol v-if="detail[5] && detail[5].length > 0">
          <li
            v-for="(item, index) in detail[5]"
            :key="index"
          >
            <a
              target="_blank"
              :href="item.file_url"
            >
              {{ item.title }}
              <b
                v-if="item.is_new === 1"
                class="red"
              >新</b>
            </a>
          </li>
        </ol>
      </div>
      <div class="box">
        <img
          src="../../assets/images/help_dd.png"
          alt="订单"
        />
        <h4>五、订单时间规则</h4>
        <ol v-if="detail[6] && detail[6].length > 0">
          <li
            v-for="(item, index) in detail[6]"
            :key="index"
          >
            <a
              target="_blank"
              :href="item.file_url"
            >
              {{ item.title }}
              <b
                v-if="item.is_new === 1"
                class="red"
              >新</b>
            </a>
          </li>
        </ol>
      </div>
      <div
        class="box"
        style="border-right: none;"
      >
        <img
          src="../../assets/images/help_js.png"
          alt="结算"
        />
        <h4>六、结算相关</h4>
        <ol v-if="detail[7] && detail[7].length > 0">
          <li
            v-for="(item, index) in detail[7]"
            :key="index"
          >
            <a
              target="_blank"
              :href="item.file_url"
            >
              {{ item.title }}
              <b
                v-if="item.is_new === 1"
                class="red"
              >新</b>
            </a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpCenter',
  data() {
    return {
      detail: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$axios({
        method: 'get',
        url: this.$api.HelpCenter.pageData
      }).then((res) => {
        const r = res || {}
        if (r.error === 0) {
          this.detail = (r.data && r.data.data) || []
        } else {
          this.detail = []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    .module {
      display: flex;
      background: #fff;
      .box {
        flex: 1;
        padding: 60px 0 120px 100px;
        border-right: 1px solid #ccc;
        a:hover {
          color: #20A0FF;
        }
        img {
          margin-bottom: 20px;
        }
        h4 {
          margin-bottom: 10px;
          font-size: 22px;
          font-weight: 600;
        }
        ol {
          padding-left: 0;
          margin: 0;
          line-height: 30px;
          font-size: 16px;
        }
      }
    }
  }
  .module-head {
    padding: 0 30px;
    background: #fff;
    margin-bottom: 20px;
    h2 {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 40px;
      padding-top: 30px;
    }
    h1 {
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
  .module-train {
    display: flex;
    .box {
      width: 500px;
      padding: 20px 0px 60px;
      box-sizing: border-box;
      a {
        display: block;
        font-size: 16px;
        margin-bottom: 15px;
        color: #000;
      }
      a:hover {
        color: rgb(32, 160, 255);
      }
    }
  }
</style>
