var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "module-head" }, [
      _c("h2", [
        _vm._v(
          "温馨提醒：亲爱的商家们，因目前版本更新较快，以下部分内容可能与最新版本有所不符。若出现不符的情况，请以您使用的最新版本为准。"
        ),
      ]),
      _c("div", { staticClass: "module-train" }, [
        _c("div", { staticClass: "box" }, [
          _c("h1", [_vm._v("培训视频专区")]),
          _vm.detail[0] && _vm.detail[0].length > 0
            ? _c(
                "div",
                _vm._l(_vm.detail[0], function (item, index) {
                  return _c(
                    "a",
                    {
                      key: index,
                      attrs: { href: item.file_url, target: "_blank" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            `【视频】${item.title}（${item.create_time}）`
                          ) +
                          " "
                      ),
                      item.is_new === 1
                        ? _c("b", { staticClass: "red" }, [_vm._v("新")])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "box" }, [
          _c("h1", [_vm._v("培训文件专区")]),
          _vm.detail[1] && _vm.detail[1].length > 0
            ? _c(
                "div",
                _vm._l(_vm.detail[1], function (item, index) {
                  return _c(
                    "a",
                    {
                      key: index,
                      attrs: { href: item.file_url, target: "_blank" },
                    },
                    [
                      _vm._v(" " + _vm._s(`${index + 1}、${item.title}`) + " "),
                      item.is_new === 1
                        ? _c("b", { staticClass: "red" }, [_vm._v("新")])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "module",
        staticStyle: { "border-bottom": "1px solid #ccc" },
      },
      [
        _c("div", { staticClass: "box" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/help_xx.png"),
              alt: "信息",
            },
          }),
          _c("h4", [_vm._v("一、必填信息")]),
          _vm.detail[2] && _vm.detail[2].length > 0
            ? _c(
                "ol",
                _vm._l(_vm.detail[2], function (item, index) {
                  return _c("li", { key: index }, [
                    _c(
                      "a",
                      { attrs: { target: "_blank", href: item.file_url } },
                      [
                        _vm._v(" " + _vm._s(item.title) + " "),
                        item.is_new === 1
                          ? _c("b", { staticClass: "red" }, [_vm._v("新")])
                          : _vm._e(),
                      ]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "box" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/help_sp.png"),
              alt: "商品",
            },
          }),
          _c("h4", [_vm._v("二、商品相关")]),
          _vm.detail[3] && _vm.detail[3].length > 0
            ? _c(
                "ol",
                _vm._l(_vm.detail[3], function (item, index) {
                  return _c("li", { key: index }, [
                    _c(
                      "a",
                      { attrs: { target: "_blank", href: item.file_url } },
                      [
                        _vm._v(" " + _vm._s(item.title) + " "),
                        item.is_new === 1
                          ? _c("b", { staticClass: "red" }, [_vm._v("新")])
                          : _vm._e(),
                      ]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "box", staticStyle: { "border-right": "none" } },
          [
            _c("img", {
              attrs: {
                src: require("../../assets/images/help_yx.png"),
                alt: "营销",
              },
            }),
            _c("h4", [_vm._v("三、营销相关")]),
            _vm.detail[4] && _vm.detail[4].length > 0
              ? _c(
                  "ol",
                  _vm._l(_vm.detail[4], function (item, index) {
                    return _c("li", { key: index }, [
                      _c(
                        "a",
                        { attrs: { target: "_blank", href: item.file_url } },
                        [
                          _vm._v(" " + _vm._s(item.title) + " "),
                          item.is_new === 1
                            ? _c("b", { staticClass: "red" }, [_vm._v("新")])
                            : _vm._e(),
                        ]
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        ),
      ]
    ),
    _c("div", { staticClass: "module" }, [
      _c("div", { staticClass: "box" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/help_fh.png"),
            alt: "发货",
          },
        }),
        _c("h4", [_vm._v("四、发货相关")]),
        _vm.detail[5] && _vm.detail[5].length > 0
          ? _c(
              "ol",
              _vm._l(_vm.detail[5], function (item, index) {
                return _c("li", { key: index }, [
                  _c(
                    "a",
                    { attrs: { target: "_blank", href: item.file_url } },
                    [
                      _vm._v(" " + _vm._s(item.title) + " "),
                      item.is_new === 1
                        ? _c("b", { staticClass: "red" }, [_vm._v("新")])
                        : _vm._e(),
                    ]
                  ),
                ])
              }),
              0
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "box" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/help_dd.png"),
            alt: "订单",
          },
        }),
        _c("h4", [_vm._v("五、订单时间规则")]),
        _vm.detail[6] && _vm.detail[6].length > 0
          ? _c(
              "ol",
              _vm._l(_vm.detail[6], function (item, index) {
                return _c("li", { key: index }, [
                  _c(
                    "a",
                    { attrs: { target: "_blank", href: item.file_url } },
                    [
                      _vm._v(" " + _vm._s(item.title) + " "),
                      item.is_new === 1
                        ? _c("b", { staticClass: "red" }, [_vm._v("新")])
                        : _vm._e(),
                    ]
                  ),
                ])
              }),
              0
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "box", staticStyle: { "border-right": "none" } },
        [
          _c("img", {
            attrs: {
              src: require("../../assets/images/help_js.png"),
              alt: "结算",
            },
          }),
          _c("h4", [_vm._v("六、结算相关")]),
          _vm.detail[7] && _vm.detail[7].length > 0
            ? _c(
                "ol",
                _vm._l(_vm.detail[7], function (item, index) {
                  return _c("li", { key: index }, [
                    _c(
                      "a",
                      { attrs: { target: "_blank", href: item.file_url } },
                      [
                        _vm._v(" " + _vm._s(item.title) + " "),
                        item.is_new === 1
                          ? _c("b", { staticClass: "red" }, [_vm._v("新")])
                          : _vm._e(),
                      ]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }